@charset "UTF-8";
.avatar-uploader .el-upload .el-upload--text[data-v-4f1c50b4] {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload[data-v-4f1c50b4]:hover {
  border-color: #409eff;
}
.avatar-uploader-icon[data-v-4f1c50b4] {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar[data-v-4f1c50b4] {
  width: 178px;
  height: 178px;
  display: block;
}

/* 模块标题样式 */
.text_box[data-v-4f1c50b4] {
  width: 100%;
  height: 30px;
  font-size: 16px;
  font-weight: 600;
}
.text_box span[data-v-4f1c50b4] {
    color: #0270c1 !important;
}
.text_box .el-button span[data-v-4f1c50b4] {
    color: #ffffff !important;
}
.text_box_line[data-v-4f1c50b4] {
  width: 100%;
  height: 2px;
  margin-top: 5px;
  margin-bottom: 10px;
  background-color: #7f7f7f;
}
