[data-v-4f1c50b4] .el-row {
  margin-bottom: 10px !important;
}
.resetPassword[data-v-4f1c50b4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 50px;
  font-size: 25px;
}
.tips[data-v-4f1c50b4] {
  font-size: 12px;
  color: black;
  font-weight: 900;
}
