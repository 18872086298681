[data-v-f9d510e4] .shrink-icon {
  z-index: 10;
}
[data-v-f9d510e4] .el-form-item {
  margin-bottom: 10px;
}
.page-search-bar[data-v-f9d510e4] {
  padding: 10px 0 10px 10px;
  background-color: #fff;
  margin-bottom: 10px;
}
[data-v-f9d510e4] .task-title span {
  cursor: pointer;
}
.page-content[data-v-f9d510e4] {
  height: calc(100% - 140px);
}
@media (max-width: 1700px) {
.page-content[data-v-f9d510e4] {
    height: calc(100% - 220px);
}
}
.vue-treeselect[data-v-f9d510e4] {
  width: 214px;
}
